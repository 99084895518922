import Swal from 'sweetalert2/dist/sweetalert2.js'
import { CustomError } from './CustomError';
import { ApiError } from './UtilsApi';

const showErrorDetailsDefault=false

export function showInfo(text:string,rest={}) {
    return Swal.fire({
        text,
        heightAuto: false,
        ...rest
    })     
}

export function showWarning(text:string,rest={}) {
    return Swal.fire({
        text,
        icon:"warning",
        heightAuto: false,
        ...rest
    })     
}

export function showSuccess(text:string,rest={}) {
    return Swal.fire({
        text,
        icon:"success",
        heightAuto: false,
        ...rest
    })     
}


export function showConfirm(text:string,rest={}) {
    return Swal.fire({
        text,
        showCancelButton: true,
        heightAuto: false,
        ...rest
    })     
}



export function showError(
    error:Error, 
    konfig:{showDetails:boolean,rest:object}={showDetails:showErrorDetailsDefault,rest:{}}
) {

    console.log("showUtilsApiError +++ START ++++++++++++++++++++++++++++++")
    for (const prop in error) {
        if (typeof error[prop] !== 'function') {
            console.error(`Tulajdonság: >>>${prop}<<< Value: `, error[prop]);
        }
      }
      console.error(error)
    console.log("showUtilsApiError +++ STOP +++++++++++++++++++++++++++++++")
 

    const dom=()=> {
        const divElement = document.createElement("div");

        const title = document.createElement("h3");
        title.innerHTML = "<b>Error message</b>";
        divElement.appendChild(title);

        const errorMessage = document.createElement("p");
        errorMessage.innerHTML = `<b>${error.message}</b>`;
        divElement.appendChild(errorMessage);        

        if (konfig.showDetails) {

            const detailsElement = document.createElement("p");
            detailsElement.innerHTML = `<b>Details</b>`;
            divElement.appendChild(detailsElement);

            const textareaElement = document.createElement("textarea");
            textareaElement.style.width = "100%";
            textareaElement.style.height = "300px";
            textareaElement.style.fontSize = "14px";
            textareaElement.textContent = error.stack || "NO STACK!!!";
            divElement.appendChild(textareaElement);
        }

        const button = document.createElement("button");
        button.innerHTML = "Copy error details to clipboard...";
        button.classList.add("swal2-confirm")
        button.classList.add("swal2-styled")
        button.addEventListener("click", function(e) {
            e.preventDefault
            navigator.clipboard.writeText(error.stack || "NO-CONTENT!!!")
            .then(() => {
                alert('Text copied to clipboard');
            })
            .catch((error) => {
                console.error('Error copying text to clipboard:', error);
                alert('Error copying text to clipboard.');
            });
        });


        divElement.appendChild(button);        

        return divElement;
    }

    return Swal.fire({
        html: dom(),
        icon:"error",
        heightAuto: false,
        ...(konfig.showDetails && {width:"80%"}),
        ...konfig.rest
    })     
}

export function showUtilsApiError(
    error:ApiError, 
    konfig:{showDetails:boolean,rest:object}={showDetails:showErrorDetailsDefault,rest:{}}
) {

    console.log("showUtilsApiError +++ START ++++++++++++++++++++++++++++++")
    for (const prop in error) {
        if (typeof error[prop] !== 'function') {
            console.error(`Tulajdonság: >>>${prop}<<< Value: `, error[prop]);
        }
      }
    console.log("showUtilsApiError +++ STOP +++++++++++++++++++++++++++++++")

    const responsestr = JSON.stringify(error.response, null, 8) 
        .replace(/\\r/g, '\r')
        .replace(/\\n/g, '\n')
        .replace(/\\t/g, '')    

    const details=
`AXIOS:
------
${responsestr}
STACK:
------
${(error.stack || "NO-STACK!!!")} `

    const tmp = `
        <div>
            <p><b>Error message: </b><br>${error.response.data.message}</p>
            <p><b>URL:</b><br>${error.response.request.responseURL}</p>
            <p><b>Details (http status: ${error.response.status})</b></p>
            <textarea style="width: 100%; height: 300px; font-size: 14px;">
            ${responsestr}
            </textarea>
            <button data-swal-toast-template="#my-template">
                Trigger toast
            </button>
        </div>
    `;

    const dom=()=> {
        const divElement = document.createElement("div");

        const title = document.createElement("h3");
        title.innerHTML = "<b>Error message</b>";
        divElement.appendChild(title);

        const errorMessage = document.createElement("p");
        errorMessage.innerHTML = `<b>${error.response.data.message}</b>`;
        divElement.appendChild(errorMessage);        


        
        
        if (konfig.showDetails) {
            const urlElement = document.createElement("p");
            urlElement.innerHTML = `<b>URL:</b><br>${error.response.request.responseURL}`;
            divElement.appendChild(urlElement);

            const detailsElement = document.createElement("p");
            detailsElement.innerHTML = `<b>Details (http status: ${error.response.status})</b>`;
            divElement.appendChild(detailsElement);

            const textareaElement = document.createElement("textarea");
            textareaElement.style.width = "100%";
            textareaElement.style.height = "300px";
            textareaElement.style.fontSize = "14px";
            textareaElement.textContent = details;
            divElement.appendChild(textareaElement);
        }

        const button = document.createElement("button");
        button.innerHTML = "Copy error details to clipboard...";
        button.classList.add("swal2-confirm")
        button.classList.add("swal2-styled")
        button.addEventListener("click", function(e) {
            e.preventDefault
            navigator.clipboard.writeText(details)
            .then(() => {
                alert('Text copied to clipboard');
            })
            .catch((error) => {
                console.error('Error copying text to clipboard:', error);
                alert('Error copying text to clipboard.');
            });
        });


        divElement.appendChild(button);        

        return divElement;
    }

    return Swal.fire({
        html: dom(),
        icon:"error",
        heightAuto: false,
        ...(konfig.showDetails && {width:"80%"}),
        ...konfig.rest
    })     
}
