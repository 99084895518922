import React, { ReactNode, useEffect, useState,useRef } from "react";
import { components } from "../../../src-kozos/src/types/api-types";
import { loadClientCurrent, loadClientSEmployeeSWorksInDateInterval } from "../Utils/UtilsApi";
import { Weekday, dateOfPreviousDayOfWeek, getWeekday } from "../Utils/UtilsDateTime";
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import { multiPropMultiTypeComparator, nullSafeStringComparator } from "../../../src-kozos/src/utils/UtilsVSM";
import "./Timecard.css"
import { showInfo } from "../Utils/UtilsDialog";
import WorkAdatlap from "../Work/WorkAdatlap";

interface WeekOfEmployee {
    days: [
        DayOfEmployee, // 1
        DayOfEmployee, // 2
        DayOfEmployee, // 3
        DayOfEmployee, // 4
        DayOfEmployee, // 5
        DayOfEmployee, // 6
        DayOfEmployee  // 7
    ],
    employee: components["schemas"]["Employee"],
    employementRelationships: components["schemas"]["EmploymentRelationshipDTOForClient"][]
}

interface DayOfEmployee {
    datum: moment.Moment,
    work?: components["schemas"]["WorkDTOForClient"],
    workPlan?: components["schemas"]["WorkPlan"],
    employementRelationship: components["schemas"]["EmploymentRelationshipDTOForClient"]
}

export function Timecard(): ReactNode {

    const [client, setClient] = useState<components["schemas"]["ClientDTOForClient"] | null>(null);
    const [selectedWeekStart, setSelectedWeekStart] = useState<moment.Moment | null>(null);
    const [weeksOffest, setWeekOffset] = useState<number>(0);



    const handleSelectWeekStart = (newWeekStart: moment.Moment, direction: -1 | 1 | null) => {

        const firstDay: Weekday | undefined = getWeekday(client?.firstWorkDay as string)
        setSelectedWeekStart(direction != null ? newWeekStart : dateOfPreviousDayOfWeek(firstDay as Weekday, client?.timeZone as string));
        setWeekOffset(prevCount => direction != null ? prevCount + direction : 0)
    };

    useEffect(() => {
        (async () => {
            const loadedClient: components["schemas"]["ClientDTOForClient"] = await loadClientCurrent();
            setClient(loadedClient);
            const firstDay: Weekday | undefined = getWeekday(loadedClient.firstWorkDay as string)
            const clientFirstDay: moment.Moment | null = dateOfPreviousDayOfWeek(firstDay as Weekday, loadedClient?.timeZone as string)
            setSelectedWeekStart(clientFirstDay)
        })()
    }, []); // A üres tömb azt jelenti, hogy a useEffect csak a komponens mountolásakor fut le

    if (!client || !selectedWeekStart) {
        // Add meg, mit szeretnél megjeleníteni, amíg a client nincs betöltve
        return <div>Loading...</div>;
    }

    const dateStart: moment.Moment = selectedWeekStart
    const dateEnd: moment.Moment = dateStart.clone().add(1, 'week')

    return (<>
        Client timezone: {client.timeZone}; current date: {moment().tz(client?.timeZone as string).format("YYYY.M.D.")}
        <WeekSelector
            selectedWeekStart={selectedWeekStart}
            onSelectWeekStart={handleSelectWeekStart}
            weeksOffset={weeksOffest}
        />
        <hr />
        <Works
            dateStart={dateStart}
            dateEnd={dateEnd}
        />
    </>)
}

function Works({
    dateStart,
    dateEnd
}: {
    dateStart: moment.Moment
    dateEnd: moment.Moment
}) {

    const [works, setWorks] = useState<components["schemas"]["WorkDTOForClient"][]>()
    const [workForEdit,setWorkForEdit]=useState<components["schemas"]["WorkDTOForClient"]>()
    let scrollHere=useRef<String | null>(null)

    const loadWorks=async () => {
        const loadedWorks: components["schemas"]["WorkDTOForClient"][] = await loadClientSEmployeeSWorksInDateInterval(
            { start: dateStart.format("YYYY-MM-DD"), stop: dateEnd.format("YYYY-MM-DD") }
        )
        setWorks(loadedWorks)
    }

    useEffect(() => {
        loadWorks()
    }, [dateStart, dateEnd]); // A üres tömb azt jelenti, hogy a useEffect csak a komponens mountolásakor fut le    

    const scrollToComponent = (DomID:string) => {
        console.log("scroll to:"+DomID);
        
        const component = document.getElementById(DomID);
        if (component) {
            console.log("valós görgetés");
            component.scrollIntoView({ behavior: 'smooth' });
        }
      }; 

    useEffect(() => {
        if(scrollHere.current)
            scrollToComponent(scrollHere.current)
        scrollHere.current=null
    }, [scrollHere.current]);


    if (!works) {
        // Add meg, mit szeretnél megjeleníteni, amíg a client nincs betöltve
        return <div>Loading...</div>;
    }

   
    const matrix:Matrix=getMatrix({dateEnd,dateStart,works})
    const worksOfDaysOfEmployments:WorksOfDaysOfEmployments=matrix.worksOfDaysOfEmployments
    const sortedEmploymentIDs = Object.entries(worksOfDaysOfEmployments).sort(
        (a:[string, WorksOfDaysOfEmployment],b:[string, WorksOfDaysOfEmployment])=>
            multiPropMultiTypeComparator({prop:"id",type:"number",direction:"desc"})(a[1].employment,b[1].employment) 
    ); 

    const onWorkEditHandler=(work:components["schemas"]["WorkDTOForClient"])=>{
        //showInfo("Work adatlap: "+work.id)
        setWorkForEdit(work)
    }
       

   

    const MatrixKomponens=({
        onWorkEditHandler
    }:{
        onWorkEditHandler:(work:components["schemas"]["WorkDTOForClient"])=>{}
    }) => {
        const datumComparator=multiPropMultiTypeComparator({type:"datetime",dateTimeFormat:"YYYY-MM-DD",direction:"asc"})
        return(
            <div>
                <table className="employee-works-table">
                <thead>
                    <tr>
                        <th>Employee and position/ dates</th>
                        {
                            matrix.days.sort(
                                datumComparator // Nagyon fontos, hogy ezek megegyezzenek!!!
                            ).map((d:string)=>{
                                const date=moment(d,"YYYY-MM-DD")
                                return <th key={d}>{date.format("M/D")}<br/>{date.format("dddd")}</th>
                            }
                            )
                        }
                    </tr>
                </thead>
                <tbody>

                {
                    Object
                        .entries(matrix.worksOfDaysOfEmployments)
                        .sort((
                            a:[string, WorksOfDaysOfEmployment],
                            b:[string, WorksOfDaysOfEmployment]
                        )=>
                            multiPropMultiTypeComparator({prop:"employee.firstName",direction:"asc"})
                                (a[1].employment,b[1].employment) 
                        ).map(entry=>{
                            const employemntID=entry[0]
                            const worksOfDaysOfEmployment:WorksOfDaysOfEmployment=entry[1]
                            const employment=worksOfDaysOfEmployment.employment
                            return( 
                                <tr key={employemntID}>
                                    <td>
                                        <span style={{"fontSize":"9px"}}>{employment.id}</span><br/>
                                        {employment.employee?.firstName} {employment.employee?.lastName}/<br/>
                                        {employment.clientPosition?.name}
                                    </td>
                                    {
                                        Object.entries(worksOfDaysOfEmployment.worksOfDays).sort((a,b)=>
                                            datumComparator // Nagyon fontos, hogy ezek megegyezzenek!!!
                                                (a[0],b[0]) 
                                        ).map(innerEntry=>{
                                            const datumStr:string=innerEntry[0]
                                            const date=moment(datumStr,"YYYY-MM-DD").format("M/DD")
                                            const works:components["schemas"]["WorkDTOForClient"][]=
                                                innerEntry[1] as components["schemas"]["WorkDTOForClient"][]
                                            return(
                                                <td key={employemntID+"-"+datumStr}>
                                                    <span style={{"fontSize":"9px"}}>{date}</span><br/>
                                                    {
                                                        works.sort(
                                                            multiPropMultiTypeComparator({
                                                                prop:"checkIn",
                                                                direction:"asc",
                                                                type:"datetime",
                                                                dateTimeFormat:"YYYY-MM-DD HH:mm:ss"
                                                            })
                                                        ).map(w=>{
                                                            return(
                                                                <span key={`${w.id}-${w.version}`}>
                                                                    <MiniWork 
                                                                        work={w} 
                                                                        onWorkEditHandler={onWorkEditHandler}/> 
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                    })
                }


                </tbody>
                </table>
            </div>            
        )
    }

    return (
        <div>
            
            {   !workForEdit && 
            
                <>
                    All works: {works.length} pcs
                    <MatrixKomponens onWorkEditHandler={onWorkEditHandler}/>
                </>
                
            }
            {
                workForEdit &&
                <WorkAdatlap 
                    button={
                        <Button 
                            variant="primary" 
                            onClick={()=>{
                                const workForEditID=workForEdit.id
                                setWorkForEdit(null)
                                scrollHere.current="miniwork-"+workForEditID
                            }} 
                        >
                            Back
                        </Button>
                    }
                    entitas={workForEdit} 
                    onSaveOrUpdate={(newWork)=>{

                        // showInfo("A work módosult! New version: "+newWork.version)
                        // // TODO: ha a check-in másik hétre került akkor el kell távolítani a listából
                        // setWorks(prevWorks => {
                        //     const newWorks=prevWorks.map(prevWork=>prevWork.id==newWork.id?newWork:prevWork)
                        //     return newWorks;
                        //   });                        
                        // setWorkForEdit(null)

                        loadWorks()
                        setWorkForEdit(null)
                        scrollHere.current="miniwork-"+newWork.id
                    }}/>
            }
        </div>
    );

}

function MiniWork({
    work,
    debug=true,
    onWorkEditHandler
}:{
    work:components["schemas"]["WorkDTOForClient"]
    debug?:boolean,
    onWorkEditHandler:(work:components["schemas"]["WorkDTOForClient"])=>{}
}) {
    const checkIn:moment.Moment=moment(work.checkIn,"YYYY-MM-DD HH:mm:ss")
    const checkOut:moment.Moment | null=work.checkOut?moment(work.checkOut,"YYYY-MM-DD HH:mm:ss"):null
    const dateDifferent:boolean | null=checkIn && checkOut?checkIn.format("YYYY-MM-DD")!=checkOut.format("YYYY-MM-DD"):null
    return(
        <span 
            id={"miniwork-"+work.id}
            className={`timecard-miniwork mb-1 p-1 ${!checkOut && "without-check-out"}`} 
            onClick={()=>{
                onWorkEditHandler(work)
            }}
        >
            {debug && <span className="id-debug">id:{work.id} ver.:{work.version}<br/></span>}
            {checkIn.format("HH:mm")}-
            { checkOut &&
                <span 
                    className={`checkOut ${dateDifferent && "warning"}`}
                    {...(dateDifferent && { title: "DIFFERENT DAYS: "+checkOut.format("MM/DD/YYYY HH:mm")})}
                >
                    {dateDifferent && "..."}{checkOut.format("HH:mm")}
                </span>
            }
            { !checkOut &&
                <span>
                    ...
                </span>
            }
        </span>
    )
}

function WeekSelector({
    selectedWeekStart,
    onSelectWeekStart,
    weeksOffset
}: {
    selectedWeekStart: moment.Moment,
    onSelectWeekStart: (newWeekStart: moment.Moment, direction: -1 | 1 | null) => void,
    weeksOffset: number | null
}) {

    const handleWeek = (direction: -1 | 1 | null) => {
        onSelectWeekStart(
            selectedWeekStart.clone().add(direction, 'week'),
            direction);
    };

    const weekStart: moment.Moment = selectedWeekStart
    const weekEnd: moment.Moment = selectedWeekStart.clone().add(6, 'days')
    const currentYear: number = moment().year()
    const yearStart: number = weekStart.year()
    const yearEnd: number = weekEnd.year()
    const showStartYear: boolean = yearStart != currentYear
    const showEndYear: boolean = yearStart != yearEnd

    return (
        <div>
            <Button className="m-2 ms-0" onClick={() => handleWeek(-1)}>Previous Week</Button>
            <Button className="m-2" onClick={() => handleWeek(1)}>Next Week</Button>
            <Button className="m-2" onClick={() => handleWeek(null)}>Current week</Button>
            <br />
            <span className="m-2 ms-0">
                {weekStart.format(`${showStartYear ? "YYYY." : ""}M.D (dddd)`) + " - " + weekEnd.format(`${showEndYear ? "YYYY." : ""}M.D (dddd)`)}
            </span>

            {weeksOffset != 0 && " Offset: " + (weeksOffset == 1 ? weeksOffset + " week" : weeksOffset + " weeks")}

        </div>
    );
}

/**
 * Ez nem egy "array" hanem egy "asszociatív objektum"
 */    
interface WorksOfDay {
    [day: string]: components["schemas"]["WorkDTOForClient"][]
}

interface WorksOfDaysOfEmployment {
    employment: components["schemas"]["EmploymentRelationshipDTOForClient"],
    worksOfDays:WorksOfDay 
}

/**
 * Ez nem egy "array" hanem egy "asszociatív objektum"
 */
interface WorksOfDaysOfEmployments {
    [employmentNumber: number]: WorksOfDaysOfEmployment
}

/**
 * @property {string[]} days - A napok neveinek tömbje.
 * @property {WorksOfDaysOfEmployments} worksOfDaysOfEmployments - Az alkalmazotti kapcsolatokhoz tartozó napokon végzett munkák tömbjei.
 */
interface Matrix {
    days: string[]
    worksOfDaysOfEmployments: WorksOfDaysOfEmployments
}

/**
 * Visszakapott adatsrtuktúra példa:
 * 
 *   Példa adatstruktúra: az első szinten lévő filed-ek/kulcsok az employment ID-jai!
 *  const data = {
 *      36: {
 *          employment: {...employmentObject...},
 *          worksOfDays: {
 *          "2023-08-01": [{...workObject...}, {...workObject...}, {...workObject...}],
 *          "2023-08-02": [{...workObject...}],
 *          "2023-08-03": []
 *          }
 *      },
 *      78: {
 *          employment: {...employmentObject...},
 *          worksOfDays: {
 *          "2023-08-01": [{...workObject...}, {...workObject...}, {...workObject...}],
 *          "2023-08-02": [{...workObject...}],
 *          "2023-08-03": []
 *          }
 *      }
 *  };
 * 
 * @param param0 
 * @returns 
 */
function getMatrix({
    dateStart,
    dateEnd,
    works
} : {
    dateStart:moment.Moment,
    dateEnd:moment.Moment,
    works:components["schemas"]["WorkDTOForClient"][]
}
 ) : Matrix{

    // Napok meghatarozasa
    const days:string[]=[]
    const ciklusOnDateStart=dateStart.clone()
    while (ciklusOnDateStart.isBefore(dateEnd)) {
        const nap:moment.Moment=ciklusOnDateStart
        const dayKey=nap.format('YYYY-MM-DD')
        days.push(dayKey)
        ciklusOnDateStart.add(1, 'days');
    }

        
    // Matrix kialakitasa
    const worksOfDaysOfEmployments:WorksOfDaysOfEmployments={}
    works.forEach(w=>{
        // Adatok elokeszitese
        const employee:components["schemas"]["EmployeeDTOForClient"]=
            w.employee as components["schemas"]["EmployeeDTOForClient"]
        const employment:components["schemas"]["EmploymentRelationshipDTOForClient"]=
            w.employmentRelationship as components["schemas"]["EmployeeAndEmploymentRelationshipDTOForClient"]
        const employmentID:number=employment.id as number
        const checkInStr:string=w.checkIn as string
        const checkIn:moment.Moment=moment(checkInStr,"YYYY-MM-DD")
        /**
         * YYYY-MM-DD formában
         */
        const checkinDay:string=checkIn.format("YYYY-MM-DD")

        // ...

        let worksOfDaysOfEmployment:WorksOfDaysOfEmployment=worksOfDaysOfEmployments[employmentID]
        if(!worksOfDaysOfEmployment) {
            worksOfDaysOfEmployment={
                employment:employment,
                worksOfDays:{} // Asszociatív tömb
            }
            worksOfDaysOfEmployments[employmentID]=worksOfDaysOfEmployment
        }
        days.forEach((day:string)=>{ // YYYY-MM-DD
            let worksOfDays:components["schemas"]["WorkDTOForClient"][]=worksOfDaysOfEmployments[employmentID].worksOfDays[day]
            if(!worksOfDays) {
                worksOfDays=[]
                worksOfDaysOfEmployments[employmentID].worksOfDays[day]=worksOfDays
            }
            
            if(checkinDay==day)
                worksOfDays.push(w)
        })
    })    
    
    return {
        days,
        worksOfDaysOfEmployments
    }
}