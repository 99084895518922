import moment from 'moment-timezone';

export enum Weekday {
  SUNDAY = 'SUNDAY',       // 0
  MONDAY = 'MONDAY',       // 1
  TUESDAY = 'TUESDAY',     // 2
  WEDNESDAY = 'WEDNESDAY', // 3
  THURSDAY = 'THURSDAY',   // 4
  FRIDAY = 'FRIDAY',       // 5
  SATURDAY = 'SATURDAY',   // 6 
}

export function dateOfPreviousDayOfWeek(targetDayOfWeek: Weekday, timezone: string): moment.Moment {
  const currentDate = moment().tz(timezone);
  const currentDayOfWeek = currentDate.day();
  const targetDaySorszama = Object.values(Weekday).indexOf(targetDayOfWeek);
  // Számoljuk ki, hány napot kell hátrébb menni a célnapot megelőzően.
  let daysToSubtract = (7 + currentDayOfWeek - targetDaySorszama) % 7;
  if (daysToSubtract === 0)
    daysToSubtract = 7;
  console.log(daysToSubtract)
  const previousDayDate = currentDate.clone().subtract(daysToSubtract, 'day');
  return previousDayDate;
}

export function getWeekday(
  day:
    "MONDAY" |
    "TUESDAY" |
    "WEDNESDAY" |
    "THURSDAY" |
    "FRIDAY" |
    "SATURDAY" |
    "SUNDAY" | string
): Weekday | undefined {
  const matchingDay: Weekday | undefined = (Weekday as any)[day as string];
  return matchingDay
}

var aryIannaTimeZones = [
  "Atlantic/Reykjavik",
  'Europe/Andorra',
  "US/Hawaii",
  "Japan"
]

const tz = "US/Hawaii"
console.log(tz + " now: " + moment().tz(tz).format("YYYY-MM-DD HH:mm"));
const dt: moment.Moment = dateOfPreviousDayOfWeek(Weekday.WEDNESDAY, tz)
console.log(dt.format("YYYY-MM-DD HH:mm") + "-" + tz)
console.log(dt.day());
console.log(Object.values(Weekday)[dt.day()]);



