import React,{ createContext,useEffect,useState } from "react";
import { components } from "../../../src-kozos/src/types/api-types";
import { loadClientCurrent } from "../Utils/UtilsApi";
import { showUtilsApiError } from "../Utils/UtilsDialog";

export const GlobalContext = createContext({
    client:null,
    setClient:()=>{}
})

export const GlobalContextProvider = ({children}) => {
    const [client,setClient]=useState<components["schemas"]["ClientDTOForClient"]>()
    const value={client,setClient}

    useEffect(()=>{
        console.log("Layout1 useEffect **************************************");
        (async()=>{
          let client:components["schemas"]["ClientDTOForClient"]
            try {
              client=await loadClientCurrent()
            } catch(error) {
              showUtilsApiError(error)
              return;
            }
            setClient(client)
        })()
      },[])


    return (
        <GlobalContext.Provider
            value={value}>{children}
        </GlobalContext.Provider>
    )
}