/**
 * Adminkent jelentkeztunk-e be
 * @function isAdminRole
 * @param {any} keycloak
 * @returns {any}
 */
export function isAdminRole(keycloak) {
    return keycloak?.realmAccess?.roles?.some(e => {
        return "admin"===e.toLowerCase();
    })
}




export function getLoggedInUser(keycloak) {
    return keycloak?.tokenParsed?.preferred_username    
}

export function hasAnyRealmRole(keycloak) {  
    return keycloak?.realmAccess?.roles?.some(e => {
        return (
            "client"===e.toLowerCase()
        )
    })
}

export function checkResourceAccessRole(keycloak) {
    return keycloak?.resourceAccess?.[import.meta.env.VITE_KEYCLOAK_CLIENT]?.roles?.some(e => {
        return (
            "client"===e.toLowerCase()
        )
    })
}