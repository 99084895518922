

import NavbarVSM from "./Navbar/NavbarVSM"
import Test from "./tmp/Test"
import Keycloak from 'keycloak-js'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import "./App.scss"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React from 'react'
import ReactDOM from 'react-dom/client'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { height } from "@mui/system"
import * as kcutils from "./Utils/keycloakutil"
import { useKeycloak } from '@react-keycloak/web';
import * as UtilsVSM from "./UtilsVSM"
import Layout1 from "./Layout/Layout1"
import LayoutListView1 from "./Layout/LayoutListView1"
import { ProSidebarProvider } from 'react-pro-sidebar';
import { useParams } from 'react-router-dom';
import MaxWidthDialog from "./tmp/MaxWidthDialog"
import LayoutListAndDatasheetView from "./Layout/LayoutListAndDatasheetView"
import { useIdleTimer } from 'react-idle-timer/legacy'
import { useEffect, useState,useContext } from 'react'
import { ElsoProvider } from "./UresjaratFigyelo/ElsoContext"
import { UresJaratFigyelo } from "./UresjaratFigyelo/UresjaratFigyelo"
import WorkTabla from "./Work/WorkTabla"
import WorkAdatlap from "./Work/WorkAdatlap"
import {
  setAccessToken as UtilsApiSetAccessToken,
  clearAccessToken as UtilsApiClearAccessToken
} from "./Utils/UtilsApi"
import { Timecard } from "./timecard/Timecard"
import { GlobalContextProvider } from "./context/GlobalContext"


export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedin: false
    };

    this.keycloak = new Keycloak({
      url: import.meta.env.VITE_KEYCLOAK_URL,
      realm: 'SpringBootKeycloak',
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENT,
      minValidity: 30
    });

  }

  /*
  ██   ██ ███████ ██    ██  ██████ ██       ██████   █████  ██   ██     ███████ ██    ██ ███████ ███    ██ ████████
  ██  ██  ██       ██  ██  ██      ██      ██    ██ ██   ██ ██  ██      ██      ██    ██ ██      ████   ██    ██
  █████   █████     ████   ██      ██      ██    ██ ███████ █████       █████   ██    ██ █████   ██ ██  ██    ██
  ██  ██  ██         ██    ██      ██      ██    ██ ██   ██ ██  ██      ██       ██  ██  ██      ██  ██ ██    ██
  ██   ██ ███████    ██     ██████ ███████  ██████  ██   ██ ██   ██     ███████   ████   ███████ ██   ████    ██
  */

  handleOnEvent = async (event, error) => {
    if (event === 'onAuthSuccess') {
      if (this.keycloak.authenticated) {
        this.setState({ loggedin: true })
        if (UtilsVSM.isEnvDev()) {
          console.log("Username" + this.keycloak.tokenParsed.preferred_username)
          console.log(this.keycloak);
          console.log("Token: " + this.keycloak.token)
        }
        UtilsApiSetAccessToken(this.keycloak.token)
      }
    } else if(event === 'onTokenExpired')  {
      console.log("Token expired!!!");    

      try {
        const refreshed = await this.keycloak.updateToken(60);
        if (refreshed) {
          console.log('Token was successfully refreshed');
          console.log(this.keycloak);
          console.log("Frissitett access token: " + this.keycloak.token);
          UtilsApiSetAccessToken(this.keycloak.token)
        } else {
          console.log('Token is still valid');
        }
      } catch (error) {
        console.log('Failed to refresh the token, or the session has expired');
        console.log(this.keycloak);
        this.keycloak.logout();
      }

    } else if(["onInitError","onAuthError","onAuthRefreshError"].includes(event)) {
      console.log("General auth error");
      this.keycloak.logout();
    } else {
      console.log("ReactKeycloakProvider event: "+event)
    }
  }

  bigyo = () => {

  }


  render() {
    const initOptions = {
      pkceMethod: 'S256',
      onLoad: 'login-required'
    }

    const komponens = this.keycloak == null || !kcutils.checkResourceAccessRole(this.keycloak) ? 
      <MissingPermission /> : 
      <GlobalContextProvider>
        <ElsoProvider><PermissionsOK/></ElsoProvider>
      </GlobalContextProvider>


    /*
    ██   ██ ███████ ██    ██  ██████ ██       ██████   █████  ██   ██
    ██  ██  ██       ██  ██  ██      ██      ██    ██ ██   ██ ██  ██
    █████   █████     ████   ██      ██      ██    ██ ███████ █████
    ██  ██  ██         ██    ██      ██      ██    ██ ██   ██ ██  ██
    ██   ██ ███████    ██     ██████ ███████  ██████  ██   ██ ██   ██


    ██████  ██████   ██████  ██    ██ ██ ██████  ███████ ██████
    ██   ██ ██   ██ ██    ██ ██    ██ ██ ██   ██ ██      ██   ██
    ██████  ██████  ██    ██ ██    ██ ██ ██   ██ █████   ██████
    ██      ██   ██ ██    ██  ██  ██  ██ ██   ██ ██      ██   ██
    ██      ██   ██  ██████    ████   ██ ██████  ███████ ██   ██


    */
    return (
      <ReactKeycloakProvider
        authClient={this.keycloak}
        initOptions={initOptions}
        LoadingComponent={<LoadingComponent></LoadingComponent>}
        onEvent={this.handleOnEvent}
      >
        {komponens}
      </ReactKeycloakProvider>
    )
  }
}

function MissingPermission() {
  const { keycloak } = useKeycloak();
  const handleLogout = () => {
    UtilsApiClearAccessToken()
    keycloak.logout();
  };
  return (
    <p className="m-3">
      Missing or invalid permissions. Please contact the administrator.&nbsp;
      <a href="#" onClick={handleLogout}>Logout</a>
    </p>
  )
}

function PermissionsOK() {
  const { keycloak } = useKeycloak();

  const createListView = ({title,component}) => {
    return (
      <ProSidebarProvider>
        <Layout1>
          <LayoutListView1 title={title}>
            {component}
          </LayoutListView1>
        </Layout1>
      </ProSidebarProvider>
    )
  }

  const createListAndDatasheetView = ({title,component}) => {
    return (
      <ProSidebarProvider>
        <Layout1>
          <LayoutListAndDatasheetView>
            {component}
          </LayoutListAndDatasheetView>
        </Layout1>
      </ProSidebarProvider>
    )
  }  


  return (
    <>

        {/*
        ██    ██ ██████  ███████ ███████      ██  █████  ██████   █████  ████████
        ██    ██ ██   ██ ██      ██           ██ ██   ██ ██   ██ ██   ██    ██
        ██    ██ ██████  █████   ███████      ██ ███████ ██████  ███████    ██
        ██    ██ ██   ██ ██           ██ ██   ██ ██   ██ ██   ██ ██   ██    ██
         ██████  ██   ██ ███████ ███████  █████  ██   ██ ██   ██ ██   ██    ██
        */}
        <UresJaratFigyelo/>


        <Router basename="/app-client">
          <div className="App">



            <Routes>
              <Route index element={<Navigate to="/router/works" replace />} />
              <Route path="index" element={<Navigate to="/router/works" replace />} />

              


              {/*
              ██     ██  ██████  ██████  ██   ██
              ██     ██ ██    ██ ██   ██ ██  ██
              ██  █  ██ ██    ██ ██████  █████
              ██ ███ ██ ██    ██ ██   ██ ██  ██
               ███ ███   ██████  ██   ██ ██   ██
              */}


              <Route
                  path="router/works"
                  element={
                    createListAndDatasheetView({
                      component:
                      <WorkTabla 
                        ktxname="work.lista" 
                        key="work.lista" />})                  
                  }
                />

              <Route
                  path="router/timecards"
                  element={
                    createListAndDatasheetView({
                      component:
                      <Timecard/>})                  
                  }
                />                

              <Route
                  path="router/works/:ID/:VERSION"
                  element={
                    createListAndDatasheetView({
                      component:
                      <><UseIDParamsWrapper c={<WorkAdatlap />} /></>})                  
                  }
                />              



             
             

            
               
              <Route path="*" element={<Navigate to="/router/works" replace />} />
            </Routes>
          </div>
          
        </Router>
    </>
  )
}

function UseIDParamsWrapper(props) {
  let { ID,VERSION } = useParams();
  console.log("Re-render: UseIDParamsWrapper:"+ID+"-"+VERSION);
  return (
    <>{React.cloneElement(props.c, { id: ID,key:ID+"-"+VERSION})}</>
  )
}


function LoadingComponent() {
  const befoglalo = {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  }

  const belso = {
    width: "200px",
    height: "200px",
    textAlign: "center"
  }
  return (
    <div style={befoglalo}>
      <div style={belso}>
        <p>Login in progress</p>
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      </div>
    </div>
  )
}


