import axios from 'axios';
import {CustomError} from './CustomError';
import {Coords} from "../types/types"
import {components } from "../../../src-kozos/src/types/api-types"
import { Dayjs } from 'dayjs';
import moment from 'moment-timezone';

const axiosInstance1 = axios.create({
    baseURL: import.meta.env.VITE_SPRING_CONTEXT_PATH + "/api-for-client/",
});

export function setAccessToken(token) {
    axiosInstance1.defaults.headers.common['Authorization'] = "Bearer " + token
    console.log(token);
}

export function clearAccessToken() {
    axiosInstance1.defaults.headers.common['Authorization'] = ""
}

axiosInstance1.interceptors.request.use(
    (config)=> {
        return config;
    },
    (error)=> {
        // Do something with request error
        return Promise.reject(error);
    }
);

export interface UtilsApiError {
    response:object,
}

axiosInstance1.interceptors.response.use(
    null, // (esponse) => {return repsonse} mellozese, ez a blokk csak akkor fut le, ha a status 200
    (error) => { //  Ez akkor fut le ha a status nem 200 (felulirható)
        throw error
    }
);


/**
 * @throws {ApiError} Amikor valami rosszul megy.
 */
export const loadClientSEmployeeSWork = async (id:number):Promise<components["schemas"]["WorkDTOForClient"]> => {
    const resp = await axiosInstance1.get("/works/" + id);
    return resp.data;
};

export const loadClientSEmployeeSWorks = async ():Promise<components["schemas"]["WorkDTOForClient"][]>=> {
    const resp = await axiosInstance1.get("/works");
    return resp.data;
};

export const loadClientCurrent = async ():Promise<components["schemas"]["ClientDTOForClient"]>=> {
    const resp = await axiosInstance1.get("/client/current");
    return resp.data;
};

export const loadClientSEmployeeSWorksPageable = async ({
    page=0,
    size=1
}:{
    page:number,
    size:number
}):Promise<components["schemas"]["PageWorkDTOForClient"]>=> {
    const resp = await axiosInstance1.get("/works/pageable",{params:{page,size}});
    return resp.data;
};

export async function saveEmployeeSWorkByClient({
    workID,
    checkIn,
    checkOut
} : {
    workID:number,
    checkIn:string,
    checkOut:string
}): Promise<any>  {
        const resp = await axiosInstance1.patch("/works/" + workID,{checkIn,checkOut});
        return resp.data;
}

export const loadClientSEmployeeSWorksInDateInterval = async (
interval:
{
    start:string,
    stop:string
}
):Promise<components["schemas"]["WorkDTOForClient"][]> => {
    const resp = await axiosInstance1.get("/works/in-date-interval",{params:interval});
    return resp.data;
};
