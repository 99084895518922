import React from 'react'
import ReactDOM from 'react-dom/client'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Popover from '../comps/Popover';
import FormGroup from '@mui/material/FormGroup';
import PropLabelMegjelenito from '../comps/PropLabelMegjelenito';
import {PropsLabelArrayMegjelenites,createDateTimePicker,createDateTimePicker2,createSwitch} from "../Utils/UtilsVsmEmployee"
import { loadClientSEmployeeSWork, saveEmployeeSWorkByClient } from '../Utils/UtilsApi';
import { showError, showSuccess, showUtilsApiError } from '../Utils/UtilsDialog';
import Button from 'react-bootstrap/Button';
import {components } from "../../../src-kozos/src/types/api-types"
import dayjs from 'dayjs';


/**
 * Prop típusok az WorkAdatlap komponenshez.
 *
 * @interface WorkAdatlapProps
 * @property {number} [id] - Az entitás azonosítója (opcionális)
 * @property {components["schemas"]["WorkDTOForClient"]} [entitas] - Az entitás adatai (opcionális)
 * @property {boolean} saved - Megadja, hogy az entitás mentve van-e
 * @property {function(components["schemas"]["WorkDTOForClient"]): void} [onSaveOrUpdate] - Művelet a mentés vagy frissítés eseményén (opcionális)
 * @property {React.Component} [button] - Egy React komponens gomb megjelenítéséhez (opcionális)
 */
export interface WorkAdatlapProps {
    id?:number,
    entitas?: components["schemas"]["WorkDTOForClient"]; 
    saved: boolean ,
    onSaveOrUpdate?:(entitas: components["schemas"]["WorkDTOForClient"])=>{},
    button?:React.Component
}

export default class WorkAdatlap 
    extends React.Component<
        WorkAdatlapProps,
        { entitas?: components["schemas"]["WorkDTOForClient"]; saved: boolean }
    > {

    id?:number
    additionalButton?:React.Component
    onSaveOrUpdateListener?:(entitas: components["schemas"]["WorkDTOForClient"])=>{}
    entitas?: components["schemas"]["WorkDTOForClient"]

    constructor(props:WorkAdatlapProps) {
        super(props);
        this.id = props.id
        this.additionalButton=props.button
        this.onSaveOrUpdateListener = props.onSaveOrUpdate
        this.entitas = this.props.entitas
        this.state = {
            entitas: this.entitas,
            saved: this.entitas?.id ? true : false
        };        
    }


    /*
    ██       ██████   █████  ██████  ███████ ███    ██ ████████ ██ ████████  █████  ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ████   ██    ██    ██    ██    ██   ██ ██
    ██      ██    ██ ███████ ██   ██ █████   ██ ██  ██    ██    ██    ██    ███████ ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ██  ██ ██    ██    ██    ██    ██   ██      ██
    ███████  ██████  ██   ██ ██████  ███████ ██   ████    ██    ██    ██    ██   ██ ███████
    */

    loadEntitas = async (id:number) => {
        try {
            const work = await loadClientSEmployeeSWork(id)
            this.setState({
                entitas: work,
                saved: work.id?true:false
            })
        } catch(error) {
            showUtilsApiError(error)
        }        
    }

    /*
     ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████ ██████  ██ ██████  ███    ███  ██████  ██    ██ ███    ██ ████████
    ██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██    ██   ██ ██ ██   ██ ████  ████ ██    ██ ██    ██ ████   ██    ██
    ██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██    ██   ██ ██ ██   ██ ██ ████ ██ ██    ██ ██    ██ ██ ██  ██    ██
    ██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██    ██   ██ ██ ██   ██ ██  ██  ██ ██    ██ ██    ██ ██  ██ ██    ██
     ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██    ██████  ██ ██████  ██      ██  ██████   ██████  ██   ████    ██
    */

    componentDidMount = () => {
        if (!this.entitas && this.id) this.loadEntitas(this.id)
    }


    /*
    ███████  █████  ██    ██ ███████  ██████  ██████  ██    ██ ██████  ██████   █████  ████████ ███████
    ██      ██   ██ ██    ██ ██      ██    ██ ██   ██ ██    ██ ██   ██ ██   ██ ██   ██    ██    ██
    ███████ ███████ ██    ██ █████   ██    ██ ██████  ██    ██ ██████  ██   ██ ███████    ██    █████
         ██ ██   ██  ██  ██  ██      ██    ██ ██   ██ ██    ██ ██      ██   ██ ██   ██    ██    ██
    ███████ ██   ██   ████   ███████  ██████  ██   ██  ██████  ██      ██████  ██   ██    ██    ███████
    */

    saveOrUpdate = async () => {
        // "Regi" work
        let work:components["schemas"]["WorkDTOForClient"]=this.state.entitas as components["schemas"]["WorkDTOForClient"]
        try {
            // Modositott work
            work = await saveEmployeeSWorkByClient({
                workID:work.id as number,
                checkIn:work.checkIn as string,
                checkOut:work.checkOut as string
            })
        } catch(error) {
            showUtilsApiError(error)
            return
        }
        await showSuccess("Successful save.")
        this.setState({
            entitas: work,
            saved: true
        },()=>{
            if (this.onSaveOrUpdateListener) 
                this.onSaveOrUpdateListener(work)
        })
    }



    /*
    ██████  ███████ ███    ██ ██████  ███████ ██████
    ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
    ██████  █████   ██ ██  ██ ██   ██ █████   ██████
    ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
    ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
    */

    render() {


        /*
        ██████  ███████ ████████ ██    ██ ██████  ███    ██
        ██   ██ ██         ██    ██    ██ ██   ██ ████   ██
        ██████  █████      ██    ██    ██ ██████  ██ ██  ██
        ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██
        ██   ██ ███████    ██     ██████  ██   ██ ██   ████
        */

        const entitas=this.state.entitas
        const employee=entitas?.employee
        const work=entitas

        return (
            <>
                <h2 className="mb-4 pb-2">
                
                { this.state.entitas?.id &&
                <Popover popovertext={"ID: "+this.state.entitas.id}>
                    Employee's work 
                </Popover>
                }
                
                </h2>
                <div style={{ border: "solid 1px lightgray", padding: "20px", borderRadius: "20px", backgroundColor:"#fafafa", }}>
                    

                        <Row>
                            <Col className="col-12 p-2 pb-3 pb-sm-2">
                                {createSwitch({
                                    entitas:this.state.entitas,
                                    prop:"closedByAccounting",
                                    label:"Closed by accounting",
                                    color:"secondary",
                                    disabled:true,
                                    setState:this.setState
                                    })}
                            </Col>
                        </Row>

                    {/*
                    ███████ ███    ███ ██████  ██       ██████  ██    ██ ███████ ███████
                    ██      ████  ████ ██   ██ ██      ██    ██  ██  ██  ██      ██
                    █████   ██ ████ ██ ██████  ██      ██    ██   ████   █████   █████
                    ██      ██  ██  ██ ██      ██      ██    ██    ██    ██      ██
                    ███████ ██      ██ ██      ███████  ██████     ██    ███████ ███████
                    */}

                    <Row>
                        <Col className="col-12 p-2 pb-3 pb-sm-2">
                            <Popover popovertext={"ID: " + this.state.entitas?.employee?.id}>
                                Employee: &nbsp;
                                {

                                    PropsLabelArrayMegjelenites({
                                        obj: employee,
                                        enclosing:null,
                                        delimeter: " ",
                                        props_labels_renders: [
                                            "firstName",
                                            "lastName"],
                                        showLabels: false
                                    }) 

                                    
                                }
                                {
                                    employee?.phone &&
                                    ` (phone: ${employee.phone})`
                                }
                            </Popover>
                        </Col>
                    </Row>


                    {/*
                    ███████ ███    ███ ██████  ██       ██████  ██    ██ ███    ███ ███████ ███    ██ ████████
                    ██      ████  ████ ██   ██ ██      ██    ██  ██  ██  ████  ████ ██      ████   ██    ██
                    █████   ██ ████ ██ ██████  ██      ██    ██   ████   ██ ████ ██ █████   ██ ██  ██    ██
                    ██      ██  ██  ██ ██      ██      ██    ██    ██    ██  ██  ██ ██      ██  ██ ██    ██
                    ███████ ██      ██ ██      ███████  ██████     ██    ██      ██ ███████ ██   ████    ██


                    ██████  ███████ ██       █████  ████████ ██  ██████  ███    ██ ███████ ██   ██ ██ ██████
                    ██   ██ ██      ██      ██   ██    ██    ██ ██    ██ ████   ██ ██      ██   ██ ██ ██   ██
                    ██████  █████   ██      ███████    ██    ██ ██    ██ ██ ██  ██ ███████ ███████ ██ ██████
                    ██   ██ ██      ██      ██   ██    ██    ██ ██    ██ ██  ██ ██      ██ ██   ██ ██ ██
                    ██   ██ ███████ ███████ ██   ██    ██    ██  ██████  ██   ████ ███████ ██   ██ ██ ██


                    ██████  ██       ██████  ██   ██ ██   ██
                    ██   ██ ██      ██    ██ ██  ██  ██  ██
                    ██████  ██      ██    ██ █████   █████
                    ██   ██ ██      ██    ██ ██  ██  ██  ██
                    ██████  ███████  ██████  ██   ██ ██   ██
                    */}
                    <div className="mt-2">
                        <EmploymentRelationshipBlokk
                            employmentRelationship={entitas?.employmentRelationship}
                        />
                    </div>

                    {/*
                    ██     ██  ██████  ██████  ██   ██     ██████  ██       ██████  ██   ██ ██   ██
                    ██     ██ ██    ██ ██   ██ ██  ██      ██   ██ ██      ██    ██ ██  ██  ██  ██
                    ██  █  ██ ██    ██ ██████  █████       ██████  ██      ██    ██ █████   █████
                    ██ ███ ██ ██    ██ ██   ██ ██  ██      ██   ██ ██      ██    ██ ██  ██  ██  ██
                     ███ ███   ██████  ██   ██ ██   ██     ██████  ███████  ██████  ██   ██ ██   ██
                    */}

                    <div className="mt-4">
                        <Row>
                            <Col className="col-12 p-2 pb-3 pb-sm-2">
                                <Popover popovertext={"ID: " + work?.id}>
                                    Work
                                </Popover>
                            </Col>
                        </Row>

                        <div style={{ border: "1px solid lightgray", padding: "15px", borderRadius: "15px" }}>
                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">
                                    Time zone:&nbsp;
                                    {work?.timeZone}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">
                                    {
                                        [
                                            {p:"hourlyRateForEmployee",l:"Hourly rate",r:v=>"US$ "+v},
                                            {p:"hourlyRateForEmployeeOvertime",l:"Overtime",r:v=>"US$ "+v},
                                        ].map( (e,i) => 
                                            <PropLabelMegjelenito 
                                                o={work}
                                                key={i}
                                                p={e.p}
                                                l={e.l}
                                                r={e.r}
                                                />
                                        )
                                    }
                                </Col>
                            </Row>


                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">
                                    {createDateTimePicker2({
                                        prop: "checkIn",
                                        label: "check-in",
                                        disabled: false,
                                        entitas: this.state.entitas,
                                        onChangeHandler: (v) => {
                                            this.setState((prevstate) => ({
                                                entitas: {
                                                    ...prevstate.entitas,
                                                    ["checkIn"]: v != null ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : null
                                                },
                                                saved: false
                                            }));
                                        }
                                    })}
                                </Col>
                            </Row> 

                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">
                                {createDateTimePicker2({
                                        prop: "checkOut",
                                        label: "check-out",
                                        disabled: false,
                                        entitas: this.state.entitas,
                                        onChangeHandler: (v) => {
                                            this.setState((prevstate) => ({
                                                entitas: {
                                                    ...prevstate.entitas,
                                                    ["checkOut"]: v != null ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : null
                                                },
                                                saved: false
                                            }));
                                        }
                                    })}
                                </Col>
                            </Row> 
 



                            {
                                work?.deductibleLunchBreakDuration &&
                                <Row>
                                    <Col className="col-12 p-2 pb-3 pb-sm-2">
                                        Deductible lunch break duration: {work?.deductibleLunchBreakDuration} mins
                                    </Col>
                                </Row>
                            }
                        </div>
                    </div>

                    {/*
                    ███████ ██     ██ ██ ████████  ██████ ██   ██ ███████ ██   ██
                    ██      ██     ██ ██    ██    ██      ██   ██ ██      ██  ██
                    ███████ ██  █  ██ ██    ██    ██      ███████ █████   █████
                         ██ ██ ███ ██ ██    ██    ██      ██   ██ ██      ██  ██
                    ███████  ███ ███  ██    ██     ██████ ██   ██ ███████ ██   ██
                    */}

                    <Row>
                        <Col className="col-12 col-sm-9 p-2 pb-3 pb-sm-2">
                            <FormGroup>
                                {createSwitch({
                                    prop:"approvedByClient",
                                    label:"Approved by client",
                                    disabled:true,
                                    entitas:entitas,
                                    onChangeHandler:e => {
                                        const v= e.target.checked
                                        this.setState(
                                            prevstate => ({
                                                entitas: { 
                                                    ...prevstate.entitas, 
                                                    approvedByClient: e.target.checked,
                                                    ...(v && {problemReportedByClient: false}),
                                                    ...(v && {problemReportedByEmployee: false}),
                                                },
                                                saved: false
                                            })
                                        );
                                    }
                                })}
                                {createSwitch({
                                    prop:"problemReportedByClient",
                                    label:"Problem reported by client",
                                    color:"warning",
                                    disabled:true,
                                    entitas:entitas,
                                    onChangeHandler:e => {
                                        const v= e.target.checked
                                        this.setState(
                                            prevstate => ({
                                                entitas: { 
                                                    ...prevstate.entitas, 
                                                    problemReportedByClient: v,
                                                    ...(v && {approvedByClient: false})
                                                },
                                                saved: false
                                            })
                                        );
                                    }                                            
                                })}
                                {createSwitch({
                                    prop:"problemReportedByEmployee",
                                    label:"Problem reported by employee",
                                    color:"warning",
                                    entitas:entitas,
                                    onChangeHandler:e => {
                                        const v= e.target.checked
                                        this.setState(
                                            prevstate => ({
                                                entitas: { 
                                                    ...prevstate.entitas, 
                                                    problemReportedByEmployee: v,
                                                    ...(v && {approvedByClient: false})
                                                },
                                                saved: false
                                            })
                                        );
                                    }                                            
                                })}  
                                </FormGroup>                                
                        </Col>
                    </Row> 

                    {/*
                     █████  ██████  ██████  ██ ████████ ██  ██████  ███    ██  █████  ██
                    ██   ██ ██   ██ ██   ██ ██    ██    ██ ██    ██ ████   ██ ██   ██ ██
                    ███████ ██   ██ ██   ██ ██    ██    ██ ██    ██ ██ ██  ██ ███████ ██
                    ██   ██ ██   ██ ██   ██ ██    ██    ██ ██    ██ ██  ██ ██ ██   ██ ██
                    ██   ██ ██████  ██████  ██    ██    ██  ██████  ██   ████ ██   ██ ███████


                    ██████  ██    ██ ████████ ████████  ██████  ███    ██
                    ██   ██ ██    ██    ██       ██    ██    ██ ████   ██
                    ██████  ██    ██    ██       ██    ██    ██ ██ ██  ██
                    ██   ██ ██    ██    ██       ██    ██    ██ ██  ██ ██
                    ██████   ██████     ██       ██     ██████  ██   ████
                    */}                
                    <Row className="justify-content-between">
                        {
                            this.additionalButton &&
                            <Col>
                                    {React.cloneElement(this.additionalButton,{ className: "m-2 ms-0 mt-3" })}
                            </Col>
                        }
                        <Col className="col-auto">
                            <Button
                                onClick={this.saveOrUpdate}
                                className="m-2 ms-0 mt-3"
                                variant={this.state.saved?"primary":"danger"}
                            >
                                Save
                            </Button>
                        </Col>                        
                    </Row>
                </div>
            </>
        );
    }
}

const CurrentWorkBlock = ({ work }) => {
    return (<>


    </>)
}

const EmploymentRelationshipBlokk = ({ employmentRelationship }) => {
    return (<>


        <Row>
            <Col className="col-12 p-2 pb-3 pb-sm-2">
                <Popover popovertext={"ID: " + employmentRelationship?.id}>
                    Employment relationship
                </Popover>
            </Col>
        </Row>

        <div style={{ border: "1px solid lightgray", padding: "15px", borderRadius: "15px" }}>



            {employmentRelationship &&
                <Row>
                    <Col className="col-12 p-2 pb-3 pb-sm-2">
                        Client:&nbsp;
                        <Popover popovertext={"ID: " + employmentRelationship?.client?.id}>
                            {employmentRelationship?.client?.companyName}&nbsp;
                            {PropsLabelArrayMegjelenites({
                                obj: employmentRelationship?.client,
                                props_labels_renders: [
                                    "address",
                                    "city",
                                    "state",
                                    "zipCode"],
                                showLabels: false
                            })}
                        </Popover>
                    </Col>
                </Row>
            }




            {employmentRelationship &&
                <Row>
                    <Col className="col-12 p-2 pb-3 pb-sm-2">

                        <Popover popovertext={"ID: " + employmentRelationship?.id}>
                            Details:&nbsp;<br />
                            {PropsLabelArrayMegjelenites({
                                obj: employmentRelationship,
                                props_labels_renders: [
                                    { p: "hourlyRate", l: "Hourly rate", r: v => "US$ " + v },
                                    { p: "overTime", l: "Over time", r: v => "US$ " + v },
                                    "beginning",
                                    "ending"],
                                enclosing: null,
                                delimeter: ", ",
                                showLabels: true
                            })}
                        </Popover>
                    </Col>
                </Row>
            }


            {employmentRelationship &&
                <Row>
                    <Col className="col-12 p-2 pb-3 pb-sm-2">
                        Position:&nbsp;
                        <Popover popovertext={"ID: " + employmentRelationship?.client?.id}>
                            {employmentRelationship?.clientPosition?.name}
                        </Popover>
                    </Col>
                </Row>
            }


        </div>
    </>)
}

