import TextField1 from '../comps/datasheet/mui/TextField1';
import TextFieldWithUnit1 from '../comps/datasheet/mui/TextFieldWithUnit1';
import TextArea1 from '../comps/datasheet/mui/TextArea1';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker1 from '../comps/datasheet/mui/DatePicker1';
import DateTimePicker1 from '../comps/datasheet/mui/DateTimePicker1';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchPropsColorOverrides } from '@mui/material/Switch'
import TimezoneSelect from 'react-timezone-select'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { hasOwnProperty } from 'react-bootstrap-typeahead/types/utils';

export function PropsLabelArrayMegjelenites ({
    obj,
    props_labels_renders,
    delimeter = " - ",
    enclosing = ["(",")"],
    showLabels = true
}) {
    return (
        (s=> s && (enclosing?.[0]||"")+s+(enclosing?.[1]||""))
        (props_labels_renders
            .filter(e=>obj?.[e.p || e])
            .map(e=>{
                let v=obj[e.p || e]
                if(e.r)
                    v=e.r(v)
                const l=showLabels?(e.l || e)+": ":""
                return l+v
            }).join(delimeter))   
    )
}


const createTextField = ({p,l,d,onChangeHandler,...rest}) => {
    return (
        <TextField1
            label={l}
            disabled={d?d:false}
            value={this.state.entitas?.[p] || ""}
            onChange={onChangeHandler?onChangeHandler:e => {
                console.log("Megvaltozott")
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [p]: e.target.value },
                        saved: false
                    })
                );
            }}
            {...rest}
        />)
}

const createTextFieldWithUnit = ({p,l,d,unit,onChangeHandler,...rest}) => {
    return (
        <TextFieldWithUnit1
            label={l}
            unit={unit}
            disabled={d?d:false}
            value={this.state.entitas?.[p] || ""}
            onChange={onChangeHandler?onChangeHandler:e => {
                console.log("Megvaltozott")
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [p]: e.target.value },
                        saved: false
                    })
                );
            }}
            {...rest}
        />)
}        

const createTextArea = ({p,l,d,onChangeHandler,...rest}) => {
    return (
        <TextArea1
            label={l}
            value={this.state.entitas?.[p] || ""}
            onChange={onChangeHandler?onChangeHandler:e => {
                console.log("Megvaltozott")
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [p]: e.target.value },
                        saved: false
                    })
                );
            }}
        />)
}     

const createDatePicker= ({label,prop,onChangeHandler}) => {
    return(
        <DatePicker1
            label={label}
            value={this.state.entitas?.[prop]?dayjs(this.state.entitas?.[prop]):null}
            onChange={onChangeHandler?onChangeHandler:v => {
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [prop]: v!=null?dayjs(v).format('YYYY-MM-DD'):null },
                        saved: false
                    })
                );
            }}
        />
    )
}

export const createDateTimePicker= ({
    label,
    prop,
    onChangeHandler
}:{
    label:string,
    prop: string,
}) => {
    return(
        <DateTimePicker1
            label={label}
            value={this.state.entitas?.[prop]?dayjs(this.state.entitas?.[prop]):null}
            onChange={onChangeHandler?onChangeHandler:v => {
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, [prop]: v!=null?dayjs(v).format('YYYY-MM-DD HH:mm:ss'):null },
                        saved: false
                    })
                );
            }}
        />
    )
}        

/**
 * Létrehoz egy kapcsoló (switch) komponenst.
 *
 * @param {Object} options - Opciók objektum a kapcsolóhoz.
 * 
 * @param {object} options.entitas - Az entitás objektum, amelyhez a kapcsoló tartozik.* 
 * @param {string} options.prop - A kapcsolóhoz tartozó tulajdonság neve.
 * @param {string} options.label - A kapcsoló címke szövege.
 * 
 * @param {function} [options.onChangeHandler=null] - 
 * Az eseménykezelő függvény, amely lefut a kapcsoló állapotváltozásakor. Van egy default beépített,
 * amely a "setState" paraméterrel módosítja a state-ben a prop tulajdonságértéket,
 * és a saved állapotot
 * @param {function} options.setState - A React állapot frissítéséhez használt függvény.
 * 
 * @param {boolean} [options.disabled=false] - A kapcsoló letiltott (disabled) állapota.

 * @returns {React.ReactNode} A létrehozott kapcsoló komponens.
 */
export const createDateTimePicker2=({
    entitas,
    prop,
    label,
    onChangeHandler=null,
    setState,
    disabled=false,
}:{
    entitas: object | null | undefined,
    prop: string | null | undefined,
    label: string,
    onChangeHandler: ((e: React.ChangeEvent<HTMLInputElement>) => void) | null,
    setState?: never
    disabled?: boolean,
} | {
    entitas: object | null | undefined,
    prop: string | null | undefined,
    label: string,
    onChangeHandler?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | null,
    setState: React.Dispatch<React.SetStateAction<any>> 
    disabled?: boolean,
}
): React.ReactNode => {
    if(entitas==null || entitas == undefined || prop==null || prop==undefined || !entitas.hasOwnProperty(prop) )
        return (<>-</>)    
    return(
        <DateTimePicker1
            label={label}
            value={entitas?.[prop]==null?null:dayjs(entitas[prop])}
            disabled={disabled}

            onChange={onChangeHandler!=null?onChangeHandler:v => {
                if(setState)
                    setState(
                        (prevstate: { entitas: any; }) => ({
                            entitas: { ...prevstate.entitas, [prop]: v!=null?dayjs(v).format('YYYY-MM-DD HH:mm:ss'):null},
                            saved: false
                        })
                    );
            }}                    
        />
    )
}




/**
 * Létrehoz egy kapcsoló (switch) komponenst.
 *
 * @param {Object} options - Opciók objektum a kapcsolóhoz.
 * 
 * @param {object} options.entitas - Az entitás objektum, amelyhez a kapcsoló tartozik.* 
 * @param {string} options.prop - A kapcsolóhoz tartozó tulajdonság neve.
 * @param {string} options.label - A kapcsoló címke szövege.
 * 
 * @param {function} [options.onChangeHandler=null] - 
 * Az eseménykezelő függvény, amely lefut a kapcsoló állapotváltozásakor. Van egy default beépített,
 * amely a "setState" paraméterrel módosítja a state-ben a prop tulajdonságértéket,
 * és a saved állapotot
 * @param {function} options.setState - A React állapot frissítéséhez használt függvény.
 * 
 * @param {string} [options.color=null] - A kapcsoló színe.
 * @param {boolean} [options.disabled=false] - A kapcsoló letiltott (disabled) állapota.

 * @returns {React.ReactNode} A létrehozott kapcsoló komponens.
 */
export const createSwitch=({
    entitas,
    prop,
    label,
    onChangeHandler=null,
    setState,
    color,
    disabled=false,
}:{
    entitas: object | null | undefined,
    prop: string | null | undefined,
    label: string,
    onChangeHandler?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | null,
    setState?: React.Dispatch<React.SetStateAction<any>> 
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default',
    disabled?: boolean,
}): React.ReactNode => {
    if(entitas==null || entitas == undefined || prop==null || prop==undefined || !entitas.hasOwnProperty(prop) )
        return (<>-</>)
    return(
        <FormControlLabel control={
            <Switch
                {...(color!=null && { color: color })}
                {...(disabled==true && { disabled: true })}
                inputProps={{ 'aria-label': 'controlled' }}
                checked={entitas?.[prop]==null?false:entitas?.[prop]}
                onChange={onChangeHandler?onChangeHandler:event => {
                    setState(
                        (prevstate: { entitas: any; }) => ({
                            entitas: { ...prevstate.entitas, [prop]: event.target.checked},
                            saved: false
                        })
                    );
                }}
            />
        } label={label} />                                
    )
}